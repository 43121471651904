import { lazy } from "react";
import {
  BackgroundContainer
} from "./styles";


const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
//const TokenomicsBlock = lazy(() => import("../../components/Home/TokenomicsBlock"));
const AboutBlock = lazy(() => import ("../../components/Home/AboutBlock"));
const TeamBlock = lazy(() => import ("../../components/Home/TeamBlock"));
const PunkBlock = lazy(() => import ("../../components/Home/PunkBlock"));
const IntroBlock = lazy(() => import ("../../components/Home/IntroBlock"));
const TokenomicsBlock = lazy(() => import ("../../components/Home/TokenomicsBlock"));
const RoadmapBlock = lazy(() => import ("../../components/Home/RoadmapBlock"));
//const ChartBlock = lazy(() => import ("../../components/Home/ChartBlock"));


const Home = () => {
  return (
    <>
    <BackgroundContainer>
          <Container>
            <IntroBlock/>
          </Container>
        
          
      {/*
        <Container>
        <AboutBlock/>
        </Container>
      
        
*/}
        <Container>
        <TokenomicsBlock/>
        </Container>
        <Container>
        <PunkBlock/>
        </Container>
        <Container>
          <RoadmapBlock/>
        </Container>

        <Container>
          <TeamBlock/>
        </Container>

        <ScrollToTop />
    </BackgroundContainer>
    </>
  );
};
export default Home;
