import styled from "styled-components";

export const BackgroundContainer = styled("section")`
min-height:100vh;
background-image: url("/img/background.svg");
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  
  overflow-x: hidden;
  @media only screen and (max-width: 768px) {
    background-size:300%;
    background-position:center;
  }


`;



