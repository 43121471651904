import { lazy } from "react";
import {
  BackgroundContainer
} from "./styles";

const Container = lazy(() => import("../../common/LockerContainer"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const FarmBlock = lazy(() => import ("../../components/Farm/FarmBlock"));
const RiseLPWETHBlock = lazy(() => import ("../../components/Farm/RiseLPWETHBlock"));
const RiseWETHBlock = lazy(() => import ("../../components/Farm/RiseWETHBlock"));
const ShibLPShibBlock = lazy(() => import ("../../components/Farm/ShibLPShibBlock"));
const ZkinuZkinuBlock = lazy(() => import ("../../components/Farm/ZkinuZkinuBlock"));
const ZkinuZkinuBlockLegacy = lazy(() => import ("../../components/Farm/ZkinuZkinuBlockLegacy"));
//const NFTFarmBlock = lazy(() => import ("../../components/Farm/NFTFarmBlock"));


const Farm = () => {
  return (
  <BackgroundContainer>
    <div style={{

              
        }}>
    <Container>
      
      <ScrollToTop />
      
      <FarmBlock/>
      
      <RiseLPWETHBlock/>

      <RiseWETHBlock/>

      <ZkinuZkinuBlock/>

      <ShibLPShibBlock/>
      
    </Container>
    </div>
  </BackgroundContainer>
  );
};
/*<Contact
title={ContactContent.title}
content={ContactContent.text}
id="contact"
/>*/
export default Farm;
