import styled from "styled-components";
//background: radial-gradient(circle, rgba(83,64,240,1) 52%, rgba(83,64,240,0.8158613787311799) 100%);
//background-attachment:fixed;
export const BackgroundContainer = styled("section")`

background-image: url("/img/background.svg");
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  
  overflow-x: hidden;
  @media only screen and (max-width: 768px) {
    background-size:300%;
    background-position:center;
  }


`;

